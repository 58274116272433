import { render } from "vike/abort";
import { PageContextServer } from "vike/types";
import UserService from "../services/api/services/userService";

export const guard = async (pageContext: PageContextServer) => {
  if (!pageContext.session.user || pageContext.session.error) {
    throw render("/");
  }

  if (pageContext?.session?.user?.roles?.length) {
    if (pageContext.session.user.roles?.indexOf("scenario_manager_user") === -1)
      throw render("/unauthorized");
  }

  if (pageContext.session.accessToken) {
    //create the user service
    const userService = new UserService(
      pageContext.session.accessToken
    );

    //check the user applications
    if (pageContext?.session?.user) {
      const userApps = await userService.getApplications(pageContext.session.user.id);
      if (userApps?.data) {
        const hasConfigurator = userApps.data.find(x => x.name === "configurator");
        if (!hasConfigurator)
          throw render("/unauthorized");
      }
    }

    //check application status
    const appStatus = await userService.maintenanceStatus();
    if (appStatus?.data) {
      if (pageContext?.session?.user?.roles?.length) {
        const isAdmin =
          pageContext.session.user.roles?.indexOf("super_admin") > -1;
        if (!isAdmin) throw render("/maintenance-mode");
      }
    }
  }
};